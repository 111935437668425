<template>
  <div id="app">
    <Index></Index>
  </div>
</template>

<script>
import Index from "./components/index.vue";
export default {
  name: 'App',
  components: {
    Index
  }
}
</script>

<style >
html, body, #app {
  width: 100%!important;
  height: 100%!important;
}
</style>