<template>
  <div id="first-box">
    <!-- 上 -->
    <div id="top-box">
      <!-- <img id="light-img" src="../assets/image/light.png" /> -->
    </div>

    <!-- 中 -->
    <div id="cent-box">
      <!-- 左 -->
      <div id="cent-left-box">
        <!-- 共创官数据 -->
        <div class="top-box-between-style" v-if="!showTodaydataYesterdayData">
          <img class="title-icon-img" src="../assets/image/public-create-data.png" />
          <div id="top-box-data-count">
            <div id="public-create-data-chart-box">
              <div id="public-create-data-chart-left">
                <span>{{ pubCreateData.todayIncome.toFixed(2) }}</span>
                <span>今日收益</span>
              </div>
              <div ref="publicCreateDataChart"></div>
              <div id="public-create-data-chart-right">
                <span>{{ pubCreateData.yesterdayIncome.toFixed(2) }}</span>
                <span>昨日收益</span>
              </div>
            </div>
            <div id="public-create-data-sale-box">
              <div>
                <span class="public-create-text-sale">近7日收益</span>
                <span class="public-create-text-data">{{ pubCreateData.day7Income.toFixed(2) }}</span>
              </div>
              <div>
                <span class="public-create-text-sale">近30日收益</span>
                <span class="public-create-text-data">{{ pubCreateData.day30Income.toFixed(2) }}</span>
              </div>
              <div>
                <span class="public-create-text-sale">总收益</span>
                <span class="public-create-text-data">{{ pubCreateData.sumIncome.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 销售数据 -->
        <!-- <div class="sale-data-box" v-if="!showTodaydataYesterdayData">
          <div class="sale-data-top">
            <img class="lat-img-icon" src="../assets/image/sale-icon.png" />
            <span class="lat-text-title">销售数据</span>
          </div>
          <div id="sale-data-down">
            <div id="sale-data-chart" ref="saleDataCharts"></div>
            <div id="sale-data-vis">
              <div>
                <span class="sale-data-title">今日销售额</span>
                <span class="sale-data-data">{{
                  saleData.todaySaleAmount.toFixed(0)
                }}</span>
                <div class="sale-line-blue"></div>
              </div>
              <div>
                <span class="sale-data-title">近7日销售额</span>
                <span class="sale-data-data">{{
                  saleData.daySaleAmount.toFixed(0)
                }}</span>
                <div class="sale-line-blue"></div>
              </div>
              <div>
                <span class="sale-data-title">今日订单数</span>
                <span class="sale-data-data">{{ saleData.todayCount }}</span>
                <div class="sale-line-yellow"></div>
              </div>
              <div>
                <span class="sale-data-title">近7日订单数</span>
                <span class="sale-data-data">{{ saleData.dayCount }}</span>
                <div class="sale-line-yellow"></div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- 今日数据 -->
        <div class="top-box-between-style" v-if="showTodaydataYesterdayData">
          <img class="title-icon-img" src="../assets/image/today-data.png" />
          <div class="tody-data-box" >
            <div class="tody-data-box-content" v-for="(item, index) in todayData" :key="index">
              <div class="tody-data-box-body" v-for="(ele, i) in item" :key="i">
                <p class="tody-data-box-value">{{ ele.total }}</p>
                <p class="tody-data-box-title">{{ ele.name }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 昨日数据 -->
        <div class="sale-data-box">
          <div class="sale-data-top">
            <img class="lat-img-icon" src="../assets/image/sale-icon.png" />
            <span class="lat-text-title">昨日数据</span>
          </div>
          <div class="yesterday-data-down">
            <div class="yesterday-data-item" v-for="(item, index) in yesterdayData" :key="index">
              <p class="yesterday-data-item-value">{{ item.total }}</p>
              <p class="yesterday-data-item-title">{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 中 -->
      <div id="cent-cent-box">
        <img src="../assets/image/sun-fans-total.png" />
        <div id="cent-cent-count-total-box">
          <div v-for="(num, index) in totalFansData.totalCountArr" :key="index">
            {{ num }}
          </div>
        </div>
        <div id="cent-cent-data-vis-box" class="vis-padding">
          <!-- 地图echarts -->
          <!-- <div id="bg-circle-bottom"></div> -->
          <div id="bg-circle"></div>
          <div id="map-charts" ref="mapCharts"></div>

          <div class="cent-cent-data-count-vis-box">
            <div class="cent-cent-data-count-box">
              <span class="cent-cent-data-count">{{
                totalFansData.todayCount
              }}</span>
              <span class="cent-cent-data-text">今日新增</span>
            </div>
            <div class="cent-cent-data-count-box">
              <span class="cent-cent-data-count">{{
                totalFansData.yesterdayCount
              }}</span>
              <span class="cent-cent-data-text">昨日新增</span>
            </div>
          </div>
          <div class="cent-cent-data-count-vis-box">
            <div class="cent-cent-data-count-box">
              <span class="cent-cent-data-count">{{
                totalFansData.day7Count
              }}</span>
              <span class="cent-cent-data-text">近7日新增</span>
            </div>
            <div class="cent-cent-data-count-box">
              <span class="cent-cent-data-count">{{
                totalFansData.day30Count
              }}</span>
              <span class="cent-cent-data-text">近30日新增</span>
            </div>
          </div>
          <div id="cent-cent-data-bottom">
            <div>
              <span class="cent-cent-data-text">新粉当日产出:</span>
              <span class="cent-cent-data-count text-gap">{{
                totalFansData.newFansSaleAmount.toFixed(0)
              }}</span>
            </div>

            <div>
              <span class="cent-cent-data-text">老粉单日产出:</span>
              <span class="cent-cent-data-count text-gap">{{
                totalFansData.oldFansSaleAmount.toFixed(0)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="position: relative;">
        <div>名字</div>
        <div style="border: 1px solid #6BC9FF;position: absolute;bottom: 20px;"></div>
      </div> -->
      <!-- 右 -->
      <div id="cent-right-box">
        <div class="top-box-between-style">
          <img class="title-icon-img" src="../assets/image/today-goods-rank.png" />
          <div id="cent-right-today-goods-rank-box" class="vis-padding">
            <dv-scroll-board :config="boardTodayGoodsConfig" :style="`width:100%;height:100%`" />
          </div>
        </div>
        <div class="sale-data-box">
          <div class="sale-data-top">
            <img class="lat-img-icon" src="../assets/image/today-fans-icon.png" />
            <span class="lat-text-title">粉丝/销售额</span>
            <div class="legend">
              <div class="legend-first">
                <div id="fansIcon"></div><span>粉丝</span>
              </div>
              <div>
                <div id="saleIcon"></div><span>销售额</span>
              </div>
            </div>
          </div>

          <div id="today-fans-vis-chart" ref="todayFansVisCharts" class="vis-padding vis-padding-top0"></div>
        </div>
      </div>
    </div>

    <!-- 下 -->
    <div id="down-box">
      <!-- 左 -->
      <div id="down-left-box">
        <div class="down-box-between-style">
          <img class="title-icon-img" src="../assets/image/new-fans-list.png" />
          <div id="down-left-chart" class="down-box-between-chart-box vis-padding">
            <dv-scroll-board :config="boardNewFansConfig" :style="`width:100%;height:100%`" />
          </div>
        </div>
      </div>

      <!-- 中 -->
      <div id="down-cent-box">
        <img class="title-icon-img" src="../assets/image/near30day-sale-fans.png" />
        <div id="down-cent-chart" ref="downCentChart" class="down-box-between-chart-box vis-padding"></div>
      </div>

      <!-- 右 -->
      <div id="down-right-box">
        <div class="down-box-between-style">
          <img class="title-icon-img" src="../assets/image/new-order-list.png" />
          <div id="down-right-chart" class="down-box-between-chart-box vis-padding">
            <dv-scroll-board :config="boardNewOrderConfig" :style="`width:100%;height:100%`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import * as echarts from 'echarts'
import china from "../assets/json/100000.json"

echarts.registerMap('china', china)
var geoCoordMap = {}
var max = 0
var min = 0 // todo 
var maxSize4Pin = 200
var minSize4Pin = 40
const convertData = (data) => {
  var res = []
  for (var i = 0; i < data.length; i++) {
    var geoCoord = geoCoordMap[data[i].name]
    if (geoCoord && data[i].name != '国外') {
      res.push({
        name: data[i].name,
        coord: geoCoord,
        value: data[i].count
      })
    }
  }
  return res
}
export default {
  //import引入组件才能使用
  components: {},
  props: {},
  data() {
    return {
      boardTodayGoodsConfig: {
        header: ['序号', '商品名称', '销量'],
        headerBGC: 'rgba(24, 73, 163, 0.4)',
        evenRowBGC: 'RGBA(22, 48, 110, 0)',
        oddRowBGC: 'rgba(11, 43, 99, 0.4)',
        align: ['center', 'center', 'center'],
        data: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
      },
      boardNewFansConfig: {
        header: ['序号', '粉丝名称', '新增时间'],
        headerBGC: 'rgba(24, 73, 163, 0.4)',
        evenRowBGC: 'RGBA(22, 48, 110, 0)',
        oddRowBGC: 'rgba(11, 43, 99, 0.4)',
        align: ['center', 'center', 'center'],
        data: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
      },
      boardNewOrderConfig: {
        header: ['序号', '下单用户', '下单时间'],
        headerBGC: 'rgba(24, 73, 163, 0.4)',
        evenRowBGC: 'RGBA(22, 48, 110, 0)',
        oddRowBGC: 'rgba(11, 43, 99, 0.4)',
        align: ['center', 'center', 'center'],
        data: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]
      },

      pubCreateData: {
        todayIncome: 0,
        yesterdayIncome: 0,
        day7Income: 0,
        day30Income: 0,
        sumIncome: 0
      },

      saleData: {
        todaySaleAmount: 0,
        todayCount: 0,
        daySaleAmount: 0,
        dayCount: 0,
        newFans: 0,
        oldFans: 0
      },
      totalFansData: {
        totalCountArr: [0, 0, 0, 0, 0, 0, 0],
        todayCount: 0,
        yesterdayCount: 0,
        day7Count: 0,
        day30Count: 0,
        newFansSaleAmount: 0,
        oldFansSaleAmount: 0
      },
      // echarts
      saleDataOptionEcharts: undefined,
      todayJoinFansOptionEcharts: undefined,
      near30DayFansAndSaleOptionEcharts: undefined,
      publicCreateOptionEcharts: undefined,
      chinaOptionEcharts: undefined,
      // geoCoordMap:{}
      todayData: [],
      yesterdayData: [],
      showTodaydataYesterdayData: false

    }
  },
  // 计算属性
  computed: {},
  // 监听data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    /**
     * 今日数据
     * * */
    todayDataRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "KTT_STATISTICS_DATA_SCREEN",
        "source": JSON.stringify({ url: window.location.href, type: 0 })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>今日数据接口异常', res)
          return
        }
        this.showTodaydataYesterdayData = JSON.parse(res.data.source).show

        let dataList = JSON.parse(res.data.source).dataList
        let combinedData = []
        if (dataList.length > 0) {
          for(let i = 0; i < dataList.length / 2; i++) {
            combinedData.push([dataList[i],dataList[i + 3]])
          }
        }

        this.todayData = combinedData
      })
    },
    /**
     * 昨日数据
     * * */
    yesterdayDataRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "KTT_STATISTICS_DATA_SCREEN",
        "source": JSON.stringify({ url: window.location.href, type: -1 })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>昨日数据接口异常', res)
          return
        }
        console.log(JSON.parse(res.data.source))
        this.yesterdayData = JSON.parse(res.data.source).dataList
      })
    },

    /**
     * 共创管数据
     */
    pubCreateRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_PUB_CREATE_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>共创管数据接口异常', res)
          return
        }
        this.pubCreateData = JSON.parse(res.data.source)
        // 渲染图表
        this.publicCreateEcharts(this.pubCreateData.yesterdayIncome, this.pubCreateData.todayIncome, this.pubCreateData.coCreateCount)
      })
    },

    /**
     * 销售数据
     */
    saleDataRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_SALE_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>销售数据接口异常', res)
          return
        }
        this.saleData = JSON.parse(res.data.source)
        // 渲染图表
        this.saleDataEcharts()
      })
    },

    /**
     * 总粉丝数据
     */
    totalFansRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_FANS_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>总粉丝数据接口异常', res)
          return
        }
        let data = JSON.parse(res.data.source)
        data.totalCountArr = String(data.totalCount).padStart(7, '0').split('')
        this.totalFansData = data
        let mapData = JSON.parse(data.mapData)
        max = Number(mapData.reduce((acc, cur) => Math.max(acc, cur.count), 0))

        // 获取最小值  
        min = Number(mapData.reduce((acc, cur) => Math.min(acc, cur.count), Infinity))
        // 渲染图表
        var mapFeatures = echarts.getMap('china').geoJson.features

        mapFeatures.forEach(function (v) {
          //   // 地区名称
          if (v.properties.name) {
            var name = v.properties.name.substring(0, 2)
            if (v.properties.name == '黑龙江省' || v.properties.name == '内蒙古自治区') {
              name = v.properties.name.substring(0, 3)
            }

            //   // 地区经纬度
            geoCoordMap[name] = v.properties.center
          }
        })

        this.chinaEcharts()
      })
    },

    /**
     * 今日商品榜
     */
    todayGoodsRankRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_GOODS_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>今日商品榜数据接口异常', res)
          return
        }
        this.boardTodayGoodsConfig.data.length = 0
        this.boardTodayGoodsConfig.data = JSON.parse(res.data.source).map((item, index) => {
          return [index + 1, item.goodsName, item.goodsSumNumber]
        })
        this.boardTodayGoodsConfig = { ...this.boardTodayGoodsConfig }
      })
    },

    /**
     * 粉丝/销售额小时数据
     */
    todayJoinFansRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_TODAY_FANS_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>粉丝/销售额小时数据接口异常', res)
          return
        }
        let dataArr = JSON.parse(res.data.source)
        // 渲染图表
        this.todayJoinFansEcharts(dataArr.map(item => item.hourRange), dataArr.map(item => item.count), dataArr.map(item => item.sumAmount))
      })
    },

    /**
     * 新粉丝列表数据
     */
    newFansListRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_FANS_RANK_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>新粉丝列表数据接口异常', res)
          return
        }
        this.boardNewFansConfig.data.length = 0
        this.boardNewFansConfig.data = JSON.parse(res.data.source).map((item, index) => {
          return [index + 1, item.wxNick, item.formatDateStr]
        })
        this.boardNewFansConfig = { ...this.boardNewFansConfig }
      })
    },

    /**
     * 近30天进粉/销售额数据
     */
    near30DayFansAndSaleRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_FANS_SALE_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>近30天进粉/销售额数据数据接口异常', res)
          return
        }
        let dataArr = JSON.parse(res.data.source)
        // 渲染图表
        this.near30DayFansAndSaleEcharts(dataArr.map(item => item.day), dataArr.map(item => item.fansCount), dataArr.map(item => item.saleAmount))
      })
    },

    /**
     * 新订单列表数据
     */
    newOrderListRequest() {
      axios.post('/api', {
        "cmdCode": "DATA_GET_ALL",
        "reqCode": "DATA_ORDERS_STATISTICS",
        "source": JSON.stringify({ url: window.location.href })
      },).then((res) => {
        if (!res || res.status !== 200) {
          console.log('=============================>新订单列表数据接口异常', res)
          return
        }
        this.boardNewOrderConfig.data.length = 0
        this.boardNewOrderConfig.data = JSON.parse(res.data.source).map((item, index) => {
          return [index + 1, item.customerName, item.formatDateStr]
        })
        this.boardNewOrderConfig = { ...this.boardNewOrderConfig }
      })
    },

    /**
     * 销售数据图表
     */
    saleDataEcharts() {
      let option = {
        grid: {
          top: '0%',
          bottom: '0%',
          left: '0%',
          right: '0%',
          containLabel: true
        },
        calculable: true,
        color: ['#39C808', '#31ABE3'],
        series: [
          {
            name: '来源',
            type: 'pie',
            radius: ['13%', '46%'],
            labelLine: {
              length: 1
            },
            label: {
              normal: {
                position: 'outside',
                formatter: '{a|{b}:{c}}\n{hr|}',
                rich: {
                  hr: {
                    backgroundColor: 't',
                    borderRadius: 3,
                    width: 3,
                    height: 3,
                    // border: 'solid',
                    // borderWidth: 1,
                    // borderColor: 't',
                    padding: [3, 3, 0, -12]
                  },
                  a: {
                    padding: [-30, 15, -20, 15]
                  }
                }
              }
            },
            data: [
              { value: this.saleData.newFans, name: '新粉' },
              { value: this.saleData.oldFans, name: '老粉' }
            ]
          },
          // 边框的设置
          {
            name: '外边框',
            type: 'pie',
            clockWise: false, //顺时加载
            hoverAnimation: false, //鼠标移入变大
            center: ['50%', '50%'],
            radius: ['53%', '53%'],
            label: {
              normal: {
                show: false
              }
            },
            data: [{
              value: 9,
              name: '',
              itemStyle: {
                normal: {
                  borderWidth: 0.2,
                  borderColor: '#31ABE3'
                }
              }
            }]
          },
        ]
      }
      this.saleDataOptionEcharts = echarts.init(this.$refs.saleDataCharts)
      this.saleDataOptionEcharts.setOption(option)
    },

    /**
     * 粉丝/销售额小时图表
     */
    todayJoinFansEcharts(hourRangeArr, countArr, sumAmountArr) {
      let option = {
        grid: {
          top: '4%',
          bottom: '0%',
          left: '0%',
          right: '0%',
          containLabel: true
        },
        // legend: {
        //   left: '12%',
        //   top: '0%',
        //   textStyle: {
        //     color: '#FFF',
        //     padding: [3, 0, 0, -4]//上右下左
        //   },
        //   icon: 'circle'
        // },
        xAxis: [
          {
            type: 'category',
            data: hourRangeArr,
            axisLine: {
              lineStyle: {
                color: '#103676',
                width: 1, //x轴线的宽度
              }
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#00F1FB',
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#103676',
                width: 1, //x轴线的宽度
              }
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#AEE9F8',
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#12295C'],
                width: 1,
                type: 'solid'
              }
            }
          }
        ],
        series: [
          {
            name: '粉丝',
            type: 'bar',
            barWidth: '30%',
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 15, 15],
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#00F0FB' // 起始颜色
                }, {
                  offset: 1,
                  color: '#00B0FF' // 结束颜色rgba(255, 196, 0, 0透明度
                }]),
                opacity: function (params) {
                  // eslint-disable-next-line no-undef
                  return params.dataIndex / data.length // 根据索引计算不同位置的透明度
                }
              }
            },
            data: countArr,
          },
          {
            name: '销售额',
            type: 'bar',
            barWidth: '30%',
            itemStyle: {
              normal: {
                barBorderRadius: [15, 15, 15, 15],
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#F1CD80' // 起始颜色
                }, {
                  offset: 1,
                  color: '#E2883E' // 结束颜色rgba(255, 196, 0, 0透明度
                }]),
                opacity: function (params) {
                  // eslint-disable-next-line no-undef
                  return params.dataIndex / data.length // 根据索引计算不同位置的透明度
                }
              }
            },
            data: sumAmountArr,
          }
        ]
      }
      this.todayJoinFansOptionEcharts = echarts.init(this.$refs.todayFansVisCharts)
      this.todayJoinFansOptionEcharts.setOption(option)
    },

    /**
     * 进30天进粉/销售额 图表
     */
    near30DayFansAndSaleEcharts(dayArr, fansCountArr, saleAmountArr) {
      let option = {
        grid: {
          top: '12%',
          bottom: '3%',
          left: '0%',
          right: '0%',
          containLabel: true
        },
        color: ['#21F2EF', '#5953CD'],
        legend: {
          top: '0%',
          left: '5%',
          icon: 'circle',
          textStyle: {
            color: ['#21F2EF', '#5953CD'],
            padding: [0, 30, 0, 0]
          },
          data: ['新进粉丝数', '销售额']
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#103676',
                width: 1, //x轴线的宽度
              }
            },
            axisTick: {
              inside: true
            },
            axisLabel: {
              margin: '15',
              rotate: 1,
              color: '#00F1FB',
            },
            data: dayArr
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#103676',
                width: 1, //x轴线的宽度
              }
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#AEE9F8',
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ['#12295C'],
                width: 1,
                type: 'solid'
              }
            }
          }
        ],
        series: [
          {
            name: '新进粉丝数',
            type: 'line',
            symbol: 'none',
            smooth: true,
            lineStyle: {
              normal: {
                color: '#21F2EF',
              },
            },
            itemStyle: {
              normal: {
                areaStyle: {
                  color: {
                    type: 'linear',// 渐变类型为线性渐变
                    x: 0,// 渐变起始位置 x 坐标
                    y: 0,// 渐变起始位置 y 坐标
                    x2: 0,
                    y2: 1,
                    colorStops: [  // 渐变颜色 
                      {
                        offset: 0,
                        color: '#0ABFCD',
                      },
                      {
                        offset: 1,
                        color: 'RGBA(48, 77, 120, 0)',
                      },
                    ],
                    global: false,
                  },
                }
              }
            },
            data: fansCountArr
          },
          {
            name: '销售额',
            type: 'line',
            symbol: 'none',
            smooth: true,
            lineStyle: {
              normal: {
                color: '#5953CC',
              },
            },
            itemStyle: {
              normal: {
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [  // 渐变颜色
                      {
                        offset: 0,
                        color: '#5953CC',
                      },
                      {
                        offset: 1,
                        color: 'RGBA(37, 42, 85, 0)',
                      },
                    ],
                    global: false,
                  },
                }
              }
            },
            data: saleAmountArr
          }
        ]
      }
      this.near30DayFansAndSaleOptionEcharts = echarts.init(this.$refs.downCentChart)
      this.near30DayFansAndSaleOptionEcharts.setOption(option)
    },

    /**
     * 共创官饼图
     */
    publicCreateEcharts(todayIncome, yesterdayIncome, coCreateCount) {
      var data = []
      let component = 30
      let componentOne = ((todayIncome + yesterdayIncome) / component).toFixed(0)
      let tdIncome = parseInt((todayIncome / componentOne).toFixed(0))
      for (var i = 0; i <= component; i++) {
        if (i <= tdIncome) {
          data.push({
            value: [0, i],
            name: '今日收益\n' + todayIncome,
            // label: {show: i == parseInt((tdIncome / 2).toFixed(0))},
            // labelLine: {show: i == parseInt((tdIncome / 2).toFixed(0))}
          })
        } else {
          data.push({
            value: [0, i],
            name: '昨日收益\n' + yesterdayIncome,
            // label: {show: i == parseInt(tdIncome + parseInt(((component - tdIncome) / 2).toFixed(0)))},
            // labelLine: {show: i == parseInt(tdIncome + parseInt(((component - tdIncome) / 2).toFixed(0)))}
          })
        }
      }

      let option = {
        title: {
          text: coCreateCount,
          subtext: '共创官数',
          top: '35%',
          left: 'center',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)',
            lineHeight: 10,
            fontSize: 24
          },
          subtextStyle: {
            color: 'rgba(255, 255, 255, 1)',
            fontSize: 14
          }
        },
        series: [{
          name: '',
          type: 'pie',
          radius: ['80%', '100%'],
          center: ['50%', '50%'],
          data: data,
          color: [
            'rgba(250, 204, 20, 1)',
            'rgba(30, 196, 255, 1)',
          ],
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false,
              length: 5,
              length2: 5,
              smooth: 0.5
            }
          },
          itemStyle: {
            normal: {
              borderWidth: 3,
              borderColor: 'rgba(26, 36, 90, 1)',
            }
          }
        },

        ],
      }
      this.publicCreateOptionEcharts = echarts.init(this.$refs.publicCreateDataChart)
      this.publicCreateOptionEcharts.setOption(option)
    },

    chinaEcharts() {
      let mapData = JSON.parse(this.totalFansData.mapData)
      let option = {
        geo: [
          {
            top: '0',
            left: '0',
            right: '0',
            bottom: '-40%',
            name: '中国',
            map: 'china',
            label: {
              show: true,
              color: 'RGBA(255, 255, 255, 1)',
              formatter: (e) => {
                return e.name.substring(0, 2)
              },
            },
            itemStyle: {
              normal: {
                areaColor: {
                  type: 'linear-gradient',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [  // 渐变颜色
                    {
                      offset: 0,
                      color: 'RGBA(36, 90, 185, 1)',
                    },
                    {
                      offset: 1,
                      color: 'RGBA(93, 167, 195, 1)',
                    },
                  ],
                  global: false,
                }
              },
              emphasis: {//鼠标悬浮地图高亮
                areaColor: 'RGBA(56, 119, 227, 1)',
                label: {
                  show: true,
                  color: 'RGBA(255, 255, 255, 1)',
                  formatter: (e) => {
                    return e.name
                  }
                }
              }
            },
            regions: [
              {
                name: "南海诸岛",
                itemStyle: {
                  // 隐藏地图
                  normal: {
                    opacity: 0, // 为 0 时不绘制该图形
                  }
                },
                label: {
                  show: false // 隐藏文字
                }
              }
            ],
            data: convertData(mapData),
          },
        ],
        series: [
          {
            type: 'scatter', // 使用散点图
            coordinateSystem: 'geo', // 指定坐标系为地理坐标系
            markPoint: {
              symbol: 'pin',
              symbolSize: function (val) {
                let newVal = Math.round(((maxSize4Pin - minSize4Pin) * (val - minSize4Pin) / (max - min) + minSize4Pin))
                return newVal
              },
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: '#fff',
                    fontSize: 12,
                  },
                  formatter: function (d) { return d.value }
                }
              },
              itemStyle: {
                normal: {
                  color: {
                    type: 'linear-gradient',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [  // 渐变颜色
                      {
                        offset: 0,
                        color: 'rgba(73, 161, 207, 1)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(37, 63, 80, 1)',
                      },
                    ],
                    global: false,
                  },
                  borderColor: '#6BC9FF',
                },
                emphasis: {
                  color: '#6BC9FF',
                },
              },
              data: convertData(mapData)
            }
          },

        ]
      }

      this.chinaOptionEcharts = echarts.init(this.$refs.mapCharts)
      this.chinaOptionEcharts.setOption(option)
    },

    allMethod() {
      // 今日数据
      this.todayDataRequest()
      // 昨日数据
      this.yesterdayDataRequest()
      // 共创管数据
      this.pubCreateRequest()
      // 销售数据
      this.saleDataRequest()
      // 总粉丝数据
      this.totalFansRequest()
      // 今日商品榜数据
      this.todayGoodsRankRequest()
      // 粉丝/销售额小时分布图
      this.todayJoinFansRequest()
      // 新粉丝列表数据
      this.newFansListRequest()
      // 近30天进粉/销售额数据
      this.near30DayFansAndSaleRequest()
      // 新订单列表数据
      this.newOrderListRequest()

    }

  },
  // 生命周期，创建完成时（可以访问当前this实例）
  created() {

  },
  // 生命周期：挂载完成时（可以访问DOM元素）
  mounted() {

    this.allMethod()
    // 定时任务
    setInterval(() => {
      this.allMethod()
    }, 1000 * 60 * 10)

    // 当浏览器窗口大小改变时，重新调整地图的大小
    window.onresize = () => {
      this.saleDataOptionEcharts.resize()
      this.todayJoinFansOptionEcharts.resize()
      this.near30DayFansAndSaleOptionEcharts.resize()
      this.publicCreateOptionEcharts.resize()
      this.chinaOptionEcharts.resize()
    }
  },
  beforeCreate() { },//生命周期：创建之前
  beforeMount() { },//生命周期：挂载之前
  beforeUpdate() { },//生命周期：更新之前
  updated() { },//生命周期：更新之后
  beforeDestroy() { },//生命周期：销毁之前
  destroyed() { },//生命周期：销毁完成
  activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style scoped>
#first-box {
  width: 100%;
  height: 100%;
  /* 1920*1080 */

  background-color: #0d1732;
  background-image: url("../assets/image/grid-style.png");
  background-repeat: round;
}

/* 上 */
#top-box {
  width: 100%;
  height: calc((127 / 1080) * 100%);

  background-image: url("../assets/image/data-vis-title.png");
  background-repeat: round;
}

#light-img {
  width: calc((1142 / 1920) * 100%) !important;
  height: calc((763 / 1080) * 100%) !important;
  box-sizing: border-box;

  /* position: relative; */
  margin: 0 calc((778 / 1920) * 100%) 0 0;
  display: none;
}

/* 中 */
#cent-box {
  width: 100%;
  height: calc((560 / 1080) * 100%);

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#cent-box>div {
  background: rgba(26, 36, 90, 0.5);
  border: 2px solid #175ac9;
  box-sizing: border-box;
}

/* 中左 */
#cent-left-box {
  width: calc((460 / 1920) * 100%);
  height: 100%;
}

.top-box-between-style {
  width: 100%;
  height: calc((304 / 560) * 100%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border-bottom: #175ac9 solid 1px;
  box-sizing: border-box;
}

.tody-data-box {
  width: 100%;
  height: calc(100% - 37px);
  padding: 24px 20px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 16px;
  line-height: 1;
}

.tody-data-box-content {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid #2C45A7;
  background: #122E59;
}

.tody-data-box-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tody-data-box-body:first-child {
  border-bottom: 1px solid rgba(47, 73, 177, 1);
}

.tody-data-box-value {
  font-weight: 400;
  font-size: 28px;
  color: #FFA818;
  margin: 0;
}

.tody-data-box-title {
  font-weight: 400;
  font-size: 19px;
  color: #FFFFFF;
  margin: 15px 0 0;
}

#top-box-data-count {
  width: 100%;
  height: calc((267 / 304) * 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#public-create-data-chart-box {
  width: 100%;
  height: calc((132 / 304) * 100%);

  display: flex;
}

#public-create-data-chart-box>div {
  width: 33.33%;
  height: 100%;
}

#public-create-data-chart-left {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  font-family: PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #1ec4ff;
  line-height: 30px;
}

#public-create-data-chart-right {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  font-family: PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #facc14;
  line-height: 30px;
}

#public-create-data-chart-left,
#public-create-data-chart-right>span {
  text-align: center;
}

#public-create-data-sale-box {
  width: 100%;
  height: calc((80 / 304) * 100%);

  display: flex;
  justify-content: space-evenly;
}

#public-create-data-sale-box>div {
  width: calc((122 / 460) * 100%);
  height: 100%;
  background-image: url("../assets/image/border-horn.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.public-create-text-sale {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #58dbff;
}

.public-create-text-data {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #e5991c;
}

.sale-data-box {
  width: 100%;
  height: calc((254 / 560) * 100%);
}

.sale-data-top {
  width: 100%;
  height: calc((61 / 254) * 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lat-img-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.lat-text-title {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #dddee5;
}

.yesterday-data-down {
  width: 100%;
  height: calc((193 / 254) * 100%);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  line-height: 1;
  padding: 0 20px 20px;
  box-sizing: border-box;
}

.yesterday-data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 186, 255, 0.1);
  border: 1px solid #2F49B1;
}

.yesterday-data-item-value {
  font-weight: 400;
  font-size: 26px;
  color: #FFFFFF;
}

.yesterday-data-item-value:first-child {
  margin: 0 0 10px;
}

.yesterday-data-item-title {
  font-weight: 400;
  font-size: 19px;
  color: #FFFFFF;
  margin: 0;
}

#sale-data-down {
  width: 100%;
  height: calc((193 / 254) * 100%);

  display: flex;
  justify-content: space-between;
}

#sale-data-chart {
  width: calc((228 / 460) * 100%);
  height: 100%;
}

#sale-data-vis {
  width: calc((216 / 460) * 100%);
  height: 100%;

  display: flex;
  flex-wrap: wrap;
}

#sale-data-vis>div {
  width: 50%;
  height: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.sale-data-title {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
}

.sale-data-data {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #ffffff;
}

.sale-line-blue {
  width: 44px;
  height: 4px;
  background: #30abe3;
  border-radius: 2px;
}

.sale-line-yellow {
  width: 44px;
  height: 4px;
  background: #e5991c;
  border-radius: 2px;
}

.sale-line-blue,
.sale-line-yellow {
  margin-top: 5px;
}

/* 中中 */
#cent-cent-box {
  width: calc((920 / 1920) * 100%);
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#cent-cent-box>img {
  width: 381px;
  height: 38px;
}

#cent-cent-count-total-box {
  width: 100%;
  height: calc((120 / 560) * 100%);

  display: flex;
  align-items: center;
  justify-content: center;
}

#cent-cent-count-total-box>div {
  width: calc((60 / 920) * 100%);
  height: calc((60 / 120) * 100%);

  background: rgba(16, 48, 162, 0.3);
  box-sizing: border-box;
  border: 2px solid #175ac9;
  margin-left: calc((20 / 920) * 100%);

  display: flex;
  justify-content: center;
  align-items: center;

  /* 里面的字体样式 */
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 50px;
  color: #ffffff;
}

#cent-cent-count-total-box>div:first-child {
  margin-left: 0;
}

#cent-cent-data-vis-box {
  width: 100%;
  height: calc((402 / 560) * 100%);

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;
}

#map-charts {
  position: absolute;

  width: calc((519 / 920) * 100%);
  height: calc((397 / 402) * 100%);

  top: -7%;
}

#bg-circle {
  position: absolute;

  width: calc((340 / 920) * 100%);
  height: calc((340 / 402) * 100%);

  background-image: url("../assets/image/img-2.png");
  background-repeat: round;

  top: 0.5%;
}

#bg-circle-bottom {
  position: absolute;

  width: calc((334 / 920) * 100%);
  height: calc((93 / 402) * 100%);

  background-image: url("../assets/image/img-1.png");
  background-repeat: round;

  top: 65%;
}

.vis-padding {
  padding: 20px;
  box-sizing: border-box;
}

.vis-padding-top0 {
  padding-top: 0 !important;
}

.vis-padding-bottom0 {
  padding-bottom: 0 !important;
}

.cent-cent-data-count-vis-box {
  width: 100%;
  height: calc((115 / 402) * 100%);

  display: flex;
  justify-content: space-between;
  align-content: center;
}

.cent-cent-data-count-box {
  width: calc((202 / 920) * 100%);
  height: 100%;

  background: rgba(0, 186, 255, 0.1);
  border: 1px solid #2f49b1;
  box-sizing: border-box;
  opacity: 0.9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#cent-cent-data-bottom {
  width: 100%;
  height: calc((115 / 402) * 100%);

  /* background: #112b56; */
  /* border: 2px solid #175ac9; */
  background-image: url("../assets/image/total-fans-back.png");
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 44px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

#cent-cent-data-bottom>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cent-cent-data-text {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 26px;
  color: #ffffff;
}

.cent-cent-data-count {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 26px;
  font-weight: 700;
  color: #34dff9;
}

.text-gap {
  margin-left: 10px;
}

/* 中右 */
#cent-right-box {
  width: calc((460 / 1920) * 100%);
  height: 100%;
}

#cent-right-today-goods-rank-box {
  width: 100%;
  height: calc((279 / 305) * 100%);
  box-sizing: border-box;
}

#today-fans-vis-chart {
  width: 100%;
  height: calc((193 / 254) * 100%);
  box-sizing: border-box;
}

.legend {
  display: flex;

  position: absolute;
  right: 2%;
}

.legend>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-first {
  margin-right: 10px;
}

#saleIcon,
#fansIcon {
  margin-right: 5px;
}

.legend span {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 26px;
}

#fansIcon {
  width: 10px;
  height: 10px;
  background: #1FFFF7;
  border-radius: 50%;
}

#saleIcon {
  width: 10px;
  height: 10px;
  background: #E8A358;
  border-radius: 50%;
}

/* 下 */
#down-box {
  width: 100%;
  height: calc((393 / 1080) * 100%);

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#down-box>div {
  background: rgba(26, 36, 90, 0.5);
  border: 2px solid #175ac9;
  box-sizing: border-box;
}

/* 下左 */
#down-left-box {
  width: calc((460 / 1920) * 100%);
  height: calc((353 / 393) * 100%);
}

.down-box-between-style {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.down-box-between-chart-box {
  width: 100%;
  height: calc((315 / 353) * 100%);
}

/* 下中 */
#down-cent-box {
  width: calc((920 / 1920) * 100%);
  height: calc((353 / 393) * 100%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.title-icon-img {
  width: 381px;
  height: 82px;

  margin-top: -45px;
}

/* 下右 */
#down-right-box {
  width: calc((460 / 1920) * 100%);
  height: calc((353 / 393) * 100%);
}

/**滚动框样式 */
:deep .header {
  border-radius: 20px 20px 0px 0px !important;
}
</style>